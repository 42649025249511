import React, { useEffect } from "react";

const Timer = ({ paused, timerDuration, setTimerDuration, setPaused }) => {
  
  useEffect(() => {
    let timerId;
    if (!paused) {
      timerId = setInterval(() => {
        setTimerDuration((prev) => prev - 1);
      }, 1000);
      console.log(timerId);
    }

    return function cleanup() {
      console.log(`Clearing ${timerId}`);
      clearInterval(timerId);
    };
  }, [paused, setTimerDuration]);

  useEffect(() => {
    // stop timer when it hits 0
    if (timerDuration === 0 && paused === false) {
      setTimerDuration(0);
      setPaused(true);
    }
  }, [timerDuration, paused, setPaused, setTimerDuration]);

  return <>{timerDuration}</>;
};

export default Timer;
