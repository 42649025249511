/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from "react";
import { useState, useEffect } from "react";
import Timer from "./components/Timer";
import Button from "@mui/material/Button";

import { Box, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";

import Header from "./components/Header";

import "./App.css";

const App = () => {
  const [paragraphs, setParagraphs] = useState([]);
  const [paragraphNumber, setParagraphNumber] = useState(0);
  const [wordList, setWordList] = useState([]);

  const [curWordInput, setCurWordInput] = useState("");
  const [legitWordIndex, setLegitWordIndex] = useState(0);

  const [compareWordTrigger, setCompareWordTrigger] = useState(false);
  const [handleBackspaceTrigger, setHandleBackspaceTrigger] = useState(false);

  const [correctWordCount, setCorrectWordCount] = useState(0);
  const [userWordCount, setUserWordCount] = useState(0);
  const [userCharIndex, setUserCharIndex] = useState(0);
  const [userWordList, setUserWordList] = useState({ 0: {} });
  const [userSentenceCount, setUserSentenceCount] = useState(0);
  const [totalSentenceCount, setTotalSentenceCount] = useState(0);
  const [timerDuration, setTimerDuration] = useState(60);

  const [paused, setPaused] = useState(true);
  const [restart, setRestart] = useState(false);

  const inputRef = useRef(null); //for mobile web access

  function isLetter(str) {
    return str.length === 1 && str.match(/[a-z]/i);
  }

  function isValidSymbols(str) {
    var format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
    return format.test(str) && str.length === 1;
  }

  function checkIfValidIndex(idx, sentence_index) {
    if (
      idx === userCharIndex &&
      paragraphNumber + sentence_index === userSentenceCount
    ) {
      return "#E6DBD0";
    }
    return "white";
  }

  function checkIfEndOfSentence(word) {
    return word.indexOf(".") !== -1;
  }

  function handleRestartButtonPress() {
    setTimerDuration(60);
    setPaused(true);
    setParagraphNumber(0);
    setCorrectWordCount(0);
    setUserCharIndex(0);
    setUserSentenceCount(0);
    setUserWordList({});
    setTotalSentenceCount(0);
    setUserWordCount(0);
    setLegitWordIndex(0);
    setRestart((prev) => !prev);
  }

  function getStartCharForCurWord() {
    var correctIndex = 0;
    var cur_sentence = paragraphs.slice(paragraphNumber, paragraphNumber + 4);
    cur_sentence = cur_sentence[userSentenceCount % 4].split(" ");
    for (
      let i = 0;
      i <
      (userWordCount <= cur_sentence.length
        ? userWordCount
        : cur_sentence.length);
      i++
    ) {
      correctIndex += cur_sentence[i].length + 1; //add 1 for space
    }
    return correctIndex;
  }

  //sets array of words accordingly when paragraphs is filled
  useEffect(() => {
    var list_of_words = [];
    paragraphs.map(
      (sentence) => (list_of_words = list_of_words.concat(sentence.split(" ")))
    );
    setWordList(list_of_words);
  }, [paragraphs]);

  //fetch the paragraphs
  useEffect(() => {
    fetch(process.env.REACT_APP_AWS_URL)
      .then((response) => response.text())
      .then((data) => {
        setParagraphs(data.replace(/([.?!])\s*(?=[A-Z])/g, "$1|").split("|"));
      });
  }, [restart]);

  //used to check if most recent user word was correct, handles accordingly
  useEffect(() => {
    if (legitWordIndex !== 0) {
      if (wordList[legitWordIndex - 1] === curWordInput) {
        setCorrectWordCount((prev) => prev + 1);
        userWordList[userSentenceCount][userWordCount - 1] = [
          curWordInput,
          true,
        ];
        setUserWordList({ ...userWordList });
      } else {
        userWordList[userSentenceCount][userWordCount - 1] = [
          curWordInput,
          false,
        ];
        setUserWordList({ ...userWordList });
      }
    }
  }, [compareWordTrigger]);

  //initialize new sentence object in userWordList object when sentence complete if nonexistant
  useEffect(() => {
    if (!userWordList[userSentenceCount]) {
      userWordList[userSentenceCount] = {};
      setUserWordList({ ...userWordList });
    }
  }, [userSentenceCount]);

  //when userWordList updated, meaning there has been a submission, reset our cur user input to ""
  useEffect(() => {
    if (legitWordIndex !== 0) {
      setCurWordInput("");
    }
  }, [userWordList]);

  // handle backspace
  useEffect(() => {
    if (paragraphs.length !== 0) {
      var start_index = getStartCharForCurWord(); //this would be "0"
      if (userCharIndex - 1 < start_index) {
        if (
          userWordCount === 0 &&
          userSentenceCount > 0 &&
          userWordList[userSentenceCount - 1][
            Object.keys(userWordList[userSentenceCount - 1]).length - 1
          ][1] === false
        ) {
          setUserSentenceCount((prev) => prev - 1);
          setTotalSentenceCount((prev) => prev - 1);
          setLegitWordIndex((prev) => prev - 1);
          setUserWordCount(
            Object.keys(userWordList[userSentenceCount - 1]).length - 1
          );
          setUserCharIndex((prev) => prev - 2);
          setCurWordInput(
            userWordList[userSentenceCount - 1][
              Object.keys(userWordList[userSentenceCount - 1]).length - 1
            ][0]
          );
        } else if (userWordCount > 0) {
          if (userWordList[userSentenceCount][userWordCount - 1][1] === false) {
            setLegitWordIndex((prev) => prev - 1);
            setUserWordCount((prev) => prev - 1);
            setUserCharIndex((prev) => prev - 2);
            setCurWordInput(
              userWordList[userSentenceCount][userWordCount - 1][0]
            );
          }
        } else {
          console.log("invalid backspace, last word was right!");
        }
      } else {
        setCurWordInput((curWordInput) =>
          curWordInput.substring(0, curWordInput.length - 1)
        );
        setUserCharIndex((prev) => prev - 1);
      }
    }
  }, [handleBackspaceTrigger]);

  const detectKeyDown = (event) => {
    console.log("this is paused", paused);
    if (paused === true) {
      setPaused(false);
    }
    if (paused === true) {
      if (isLetter(event.key) || isValidSymbols(event.key)) {
        setCurWordInput((curWordInput) => curWordInput + event.key);
        setUserCharIndex((prev) => prev + 1);
      } else if (event.key === "Tab") {
        if (paragraphNumber !== 36) {
          setParagraphNumber((prev) => prev + 4);
        } else {
          setParagraphNumber((paragraphNumber) => paragraphNumber - 36);
        }
      } else if (event.key === " ") {
        setCompareWordTrigger((prev) => !prev);
        setLegitWordIndex((prev) => prev + 1);
        setUserWordCount((prev) => prev + 1);
        setUserCharIndex((prev) => prev + 1);
        inputRef.current.value = "";
        if (wordList[legitWordIndex]) {
          if (checkIfEndOfSentence(wordList[legitWordIndex])) {
            setUserSentenceCount((prev) => prev + 1);
            setTotalSentenceCount((prev) => prev + 1);
          }
        }
      } else if (event.key === "Backspace") {
        setHandleBackspaceTrigger((prev) => !prev);
      }
    }
  };

  // handles setting char index and incrementing sentence
  useEffect(() => {
    if (wordList[userWordCount] && userWordCount > 0) {
      if (checkIfEndOfSentence(wordList[legitWordIndex - 1])) {
        setUserSentenceCount((prev) => prev + 1);
        setTotalSentenceCount((prev) => prev + 1);
        setUserCharIndex(0);
        setUserWordCount(0);
      } else {
        if (userWordList[userSentenceCount][userWordCount]) {
          //if this exists, i.e: a backspace
          setUserCharIndex(
            getStartCharForCurWord() +
              userWordList[userSentenceCount][userWordCount][0].length
          );
        } else {
          setUserCharIndex(getStartCharForCurWord());
        }
      }
    }
  }, [userWordCount]);

  //proceed to next paragraph when 4 sentences done
  useEffect(() => {
    if (totalSentenceCount === 4) {
      setTotalSentenceCount(0);
      setParagraphNumber((prev) => prev + 4);
    }
  }, [totalSentenceCount]);

  // key listener for typing test
  useEffect(() => {
    document.addEventListener("keydown", detectKeyDown, true);
  }, []);

  return (
    <Box
      height="100vh"
      maxHeight="100%"
      width="100%"
      justifyContent="center"
      align="center"
      textAlign="center"
      bgcolor="white"
    >
      <Header />
      <Divider variant="middle" />
      {timerDuration !== 0 && (
        <>
          <Box display="flex" justifyContent="center" align="center">
            <Box width="100%" marginRight={1}>
              <Typography
                fontFamily="Mukta"
                fontSize={{ xs: "12pt", sm: "21pt" }}
                fontWeight="500"
                textAlign="right"
                margin={0}
              >
                Correct Word Count: {correctWordCount}
              </Typography>
            </Box>
          </Box>
          <Box
            display={{ xs: "flex", sm: "none" }}
            justifyContent="center"
            paddingTop={3}
          >
            <Typography
              fontFamily="Mukta"
              fontSize={{ xs: "12pt", sm: "21pt" }}
              fontWeight="700"
              textAlign="left"
              margin={0}
              width="80%"
            >
              Start typing below
            </Typography>
          </Box>
          <Box display={{ xs: "block", sm: "none" }} paddingTop={0}>
            <input
              style={{ width: "80%", fontSize: 18 }}
              ref={inputRef}
            ></input>
          </Box>
          <Box display="flex" justifyContent="center" paddingTop={3}>
            <Typography
              fontFamily="Mukta"
              fontSize={{ xs: "14pt", sm: "21pt" }}
              fontWeight="700"
              textAlign="left"
              margin={0}
              width="80%"
              color="#732C2C"
            >
              <Timer
                paused={paused}
                setPaused={setPaused}
                timerDuration={timerDuration}
                setTimerDuration={setTimerDuration}
              />
            </Typography>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            align="center"
            textAlign="center"
            paddingTop={0}
            height="200px"
          >
            <Box display="inline-block" textAlign="left" margin={0} width="80%">
              {paragraphs
                .slice(paragraphNumber, paragraphNumber + 4)
                .map((sentence, sentence_index) =>
                  sentence.split("").map((char, idx) => (
                    <>
                      <Box
                        component="span"
                        fontFamily="Mukta"
                        fontSize={{ xs: "12pt", sm: "21pt" }}
                        fontWeight="400"
                        bgcolor={checkIfValidIndex(idx, sentence_index)}
                      >
                        {checkIfEndOfSentence(char) ? char + " " : char}
                      </Box>
                    </>
                  ))
                )}
            </Box>
          </Box>
          {paused && (
            <Box
              display={{ xs: "none", sm: "flex" }}
              justifyContent="center"
              paddingTop="5%"
            >
              <Typography
                fontFamily="Mukta"
                fontSize={{ xs: "12pt", sm: "25pt" }}
                fontWeight="500"
                textAlign="center"
                margin={0}
                width="80%"
              >
                Start typing to begin
              </Typography>
            </Box>
          )}
        </>
      )}
      {timerDuration === 0 && (
        <Box
          display="flex"
          justifyContent="center"
          height="80vh"
          alignItems="center"
        >
          <Box display="grid" justifyContent="center">
            <Typography
              fontFamily="Mukta"
              fontSize={{ xs: "14pt", sm: "21pt" }}
              fontWeight="700"
              textAlign="center"
              margin={0}
              color="#732C2C"
            >
              {(correctWordCount / 60) * 60} WPM
            </Typography>
            <Typography
              fontFamily="Mukta"
              fontSize={{ xs: "14pt", sm: "21pt" }}
              fontWeight="700"
              textAlign="center"
              margin={0}
              color="#732C2C"
            >
              {((correctWordCount / legitWordIndex) * 100).toFixed(2)}% accuracy
            </Typography>
            <Box paddingTop={10}>
              <Button
                sx={{
                  borderRadius: 2,
                  backgroundColor: "black",
                  fontFamily: "Noto Sans",
                  fontSize: 12,
                  [`&:hover`]: { backgroundColor: "black" },
                  width: "100%",
                  height: "100px",
                }}
                variant="contained"
                onClick={handleRestartButtonPress}
              >
                <Typography
                  fontFamily="Mukta"
                  fontSize={{ xs: "14pt", sm: "21pt" }}
                  fontWeight="700"
                  textAlign="center"
                  margin={0}
                  color="white"
                >
                  Restart
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default App;
