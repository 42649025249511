import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";

const Header = () => {
  return (
    <>
      <Box
        paddingTop={0}
        paddingBottom={1}
        display="flex"
        justifyContent="space-between"
      >
        <Box paddingTop={0} display="inline-block" justifyContent="left">
          <Typography
            sx={{ flex: 1 }}
            color="#000000"
            fontSize={{ xs: 26, sm: 48 }}
            fontFamily="Mukta"
            fontWeight="500"
            paddingLeft={2}
          >
            Typing Tester Mania
          </Typography>
        </Box>
        <Box paddingTop={0} display="inline-block" justifyContent="right">
          <IconButton>
            <GitHubIcon
              sx={{
                width: { xs: 26, sm: 48 },
                height: { xs: 26, sm: 48 },
                color: "black",
              }}
            ></GitHubIcon>
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default Header;
